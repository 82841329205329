import { navigate } from "gatsby-link"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import qs from "qs"
import Separator from "../utils/Separator"
import BlockContent from "@sanity/block-content-to-react"
import Map from "../components/map/Map"
import Seo from "../components/seo"

import Layout from "../components/new-design/Layout"
import Input from "../components/form/CustomInput"
// import { numberWithCommas } from "../utils/formatUtil"
import Container from "../components/new-design/Container"
import NewImgCard from "../components/template/NewImgCard"
import NewHeadCard from "../components/template/NewHeadCard"
import { Transition } from "@headlessui/react"
export default function PropertiesPage(props) {
  const [isChange, setIsChange] = useState("GALLERY")
  const {
    location,
    pageContext: { property },
  } = props

  const schema = {
    "@context": "https://schema.org",
    "@type": "RealEstateListing",
    name: property.name,
    keywords: property.name,
    description: property.highlight,
    contentLocation: property.area,
    thumbnailUrl: property.images[0]?.asset?.url,
    primaryImageOfPage: property.images[0]?.asset?.url,
    image: property.images[0]?.asset?.url,
  }
  const metaImage = {
    src: property.images[0]?.asset?.url,
    width: property.images[0]?.asset?.metadata?.dimensions?.width,
    height: property.images[0]?.asset?.metadata?.dimensions?.height,
  }

  return (
    <div>
      <Layout>
        <Seo
          template
          image={metaImage}
          pathname={location.pathname}
          title={property.name}
          schemaMarkup={schema}
          description={property.highlight}
        />
        <div className="bg-slate-100 py-12 border-t">
          <Container>
            <div className="grid">
              <Transition
                show={isChange === "GALLERY"}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
                className="col-start-1 row-start-1 relative z-0"
              >
                <NewImgCard images={property.images} name={property.name} />
              </Transition>
              <Transition
                show={isChange === "MAP"}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
                className="col-start-1 row-start-1 relative z-0"
              >
                <div className="aspect-square  rounded-xl overflow-hidden md:aspect-video lg:aspect-[16/7] ">
                  <Map
                    lng={property.location.lng}
                    lat={property.location.lat}
                  />
                </div>
              </Transition>
            </div>
          </Container>

          <Container>
            <NewHeadCard
              property={property}
              isChange={isChange}
              setIsChange={setIsChange}
            />
          </Container>
          <Container>
            <div className="flex flex-col md:flex-row gap-6 pt-6">
              <div className="md:w-[70%] flex flex-col gap-6">
                <div className="pt-6 md:pt-0">
                  <h2 className="text-[16px] lg:text-[24px] leading-6 lg:leading-8 font-property font-normal">
                    {property.highlight}
                  </h2>
                </div>
                <div>
                  <BlockContent
                    className="text-[12px] lg:text-[16px] text-[#62718F] leading-[24px] flex flex-col gap-6"
                    blocks={property.description}
                  />
                </div>
                {property.propertyFeatures.length !== 0 && (
                  <div className="border-t border-[#62718F]/20 pt-6">
                    <h4 className=" text-xl font-semibold text-[#1F254E] pb-4">
                      Features
                    </h4>
                    <ul className="grid grid-cols-3 text-[12px] text-[#62718F] gap-4 py-4">
                      {property.propertyFeatures.map((feature, key) => {
                        return (
                          <li
                            key={key}
                            className="inline-flex gap-3 items-center"
                          >
                            <span className="h-6 w-6 bg-stone-300" />
                            <span>{feature.name}</span>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )}
                <div className="flex flex-col gap-4 text-[12px] border-t border-[#62718F]/20 pt-6">
                  <h3 className=" text-xl font-semibold text-[#1F254E] pb-4">
                    Essentials
                  </h3>
                  <p className="text-[#62718F] grid grid-cols-2">
                    <span className="font-bold">Check in: </span>{" "}
                    <span>10:00</span>
                  </p>
                  <p className="text-[#62718F] grid grid-cols-2">
                    <span className="font-bold">Check out: </span>{" "}
                    <span>10:00</span>
                  </p>
                  <p className="text-[#62718F] grid grid-cols-2">
                    <span className="font-bold">Allow Check in: </span>{" "}
                    <span>Flexible checkin days considered.</span>
                  </p>
                  <p className="text-[#62718F] grid grid-cols-2">
                    <span className="font-bold">License: </span>{" "}
                    <span>VT-300-501</span>
                  </p>
                  <p className="text-[#62718F] grid grid-cols-2">
                    <span className="font-bold">Cleaning: </span>{" "}
                    <span>Daily</span>
                  </p>
                </div>
              </div>
              <div className="md:w-[30%]">
                <PropertyDescription
                  property={property}
                  image={
                    property.images[0].asset.gatsbyImageData.images.fallback.src
                  }
                  link="/actions/villa/enquire?villaName="
                  name={property.name}
                />
              </div>
            </div>
          </Container>
          {/* <HeadCard property={property} /> */}

          {/* <PropertyInfo property={property} /> */}
        </div>
      </Layout>
    </div>
  )
}

const PropertyDescription = ({ property, link, image, name }) => {
  const value = qs.parse(
    typeof window !== "undefined"
      ? window.location.search.substring(1)
      : `name=test`
  )

  const [currentPrice, setCurrentPrice] = useState(
    value.price || property.lowPrice
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      ...value,
    },
  })
  const onSubmit = data => {
    // const searchData = {
    //   ...data,
    //   start: format(data.start, "dd-MM-yyyy"),
    //   end: format(data.end, "dd-MM-yyyy"),
    // }
    const queryString = Object.keys(data)
      .map(key => key + "=" + data[key])
      .join("&")

    navigate(`${link}${name}&${queryString}&image=${image}`)
  }
  return (
    <div className="sticky top-6 bg-white rounded-xl flex flex-col">
      <div className="p-3">
        <div className="inline-flex gap-1 text-2xl items-end">
          <span className="font-semibold">{Separator(currentPrice)}</span>
          <span className="text-gray-400 text-xs">/ Night</span>
        </div>
      </div>

      <form
        className="flex flex-col gap-4 p-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          label="Check-in Date"
          type="Date"
          className="w-full"
          field="start"
          register={register}
        />
        <Input
          label="Check-out Date"
          type="Date"
          className="w-full"
          field="end"
          register={register}
        />
        <Input
          select
          label="Adults"
          type="Select"
          className="w-full"
          options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
          field="adults"
          register={register}
        />
        <Input
          select
          label="Children"
          type="Select"
          className="w-full"
          options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
          field="children"
          register={register}
        />
        <button
          type="submit"
          className="w-full rounded-full border border-[#223C4F] bg-[#223C4F] text-white  h-12 justify-center items-center text-sm font-light hover:bg-transparent hover:text-black transition ease-in-out duration-300 outline-none focus:outline-none"
        >
          Enquire Now
        </button>
      </form>
    </div>
  )
}
