import React from "react"

export default function MenuIcons(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 9.5C17.8807 9.5 19 8.38071 19 7C19 5.61929 17.8807 4.5 16.5 4.5C15.1192 4.5 14 5.61929 14 7C14 8.38071 15.1192 9.5 16.5 9.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M6.50001 9.5C7.88072 9.5 9 8.38071 9 7C9 5.61929 7.88072 4.5 6.50001 4.5C5.1193 4.5 4 5.61929 4 7C4 8.38071 5.1193 9.5 6.50001 9.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M16.5 19.5C17.8807 19.5 19 18.3808 19 17C19 15.6193 17.8807 14.5 16.5 14.5C15.1192 14.5 14 15.6193 14 17C14 18.3808 15.1192 19.5 16.5 19.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.50001 19.5C7.88072 19.5 9 18.3808 9 17C9 15.6193 7.88072 14.5 6.50001 14.5C5.1193 14.5 4 15.6193 4 17C4 18.3808 5.1193 19.5 6.50001 19.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
