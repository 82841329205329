import React from "react"

import { HiOutlineMap, HiOutlinePhotograph } from "react-icons/hi"

export default function NewHeadCard({ property, setIsChange, isChange }) {
  return (
    <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-6">
      <div className="flex flex-col gap-2 py-3">
        <span className="text-[#62718F] text-sm">{property.area}</span>
        <h1 className="text-[#2A3D50] font-medium text-2xl md:text-4xl">
          {property.name}
        </h1>
        <ul className="flex items-center gap-4">
          <li className="text-[#62718F] text-base relative after:content-['•'] after:pl-6">
            Bedroom{" "}
            <strong className="pl-2 text-stone-900">{property.bedrooms}</strong>
          </li>
          <li className="text-[#62718F] text-base">
            Bathroom{" "}
            <strong className="pl-2 text-stone-900">
              {property.bathrooms}
            </strong>
          </li>
        </ul>
      </div>
      <div className="flex items-center gap-6">
        <button
          disabled={isChange === "MAP"}
          onClick={() => setIsChange("MAP")}
          className={`flex items-center gap-1.5 ${
            isChange === "MAP"
              ? " cursor-not-allowed border-b-2 pb-1 border-stone-900"
              : ""
          }`}
        >
          <span>
            <HiOutlineMap className="text-xl" />
          </span>
          <span>Map</span>
        </button>
        <button
          disabled={isChange === "GALLERY"}
          onClick={() => setIsChange("GALLERY")}
          className={`flex items-center gap-1.5 ${
            isChange === "GALLERY"
              ? " cursor-not-allowed border-b-2 pb-1 border-stone-900"
              : ""
          }`}
        >
          <span>
            <HiOutlinePhotograph className="text-xl" />
          </span>
          <span>Gallery</span>
        </button>
      </div>
    </div>
  )
}
