import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
// import Gallery from "./Gallery"
import SlideShow from "./SlideShow"

export default function NewImgCard({ images, name }) {
  return (
    <>
      <div className="aspect-square  rounded-xl overflow-hidden md:aspect-video lg:aspect-[16/7] grid grid-rows-2 md:grid-rows-1 md:grid-cols-2 gap-4">
        <div className="h-full w-full">
          <GatsbyImage
            image={images[0].asset.gatsbyImageData}
            className="w-full h-full object-cover"
            alt={name}
          />
        </div>
        <div className="grid-cols-2 grid-rows-2 grid gap-4">
          {images.slice(1, 5).map((image, key) => (
            <div key={key} className={`h-full w-full relative`}>
              {key === 3 && <SlideShow images={images} name={name} />}
              <GatsbyImage
                image={image.asset.gatsbyImageData}
                className="w-full h-full object-cover relative z-10"
                alt={`${name}-${key}`}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
