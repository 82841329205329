import React from "react"

import GoogleMapReact from "google-map-react"

export default function Map({ lat, lng }) {
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyCvYSJlYbYO7JNCWoCk7aEkI2pMBkWMPsw" }}
      defaultCenter={{ lat, lng }}
      defaultZoom={11}
      options={{
        maxZoom: 12,
      }}
      onGoogleApiLoaded={({ map, maps }) => {
        new maps.Marker({
          position: {
            lat,
            lng,
          },
          map,
        })
      }}
    >
      {/* <AnyReactComponent lat={38.9488491} lng={1.274873} text="My Marker" /> */}
    </GoogleMapReact>
  )
}
