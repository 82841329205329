import React from "react"

export default function CustomInput({
  className,
  label,
  type,
  select,
  note,
  options,
  register,
  field,
}) {
  return select ? (
    <div className={className + " flex flex-col gap-2 p-3 rounded-xl border"}>
      <label className="text-xs font-light text-[#62718F]" htmlFor={label}>
        {label}
      </label>
      <select
        id={label}
        type={type}
        className="w-full border-transparent font-semibold rounded outline-none focus:outline-none bg-transparent text-xs font-property"
        {...register(field)}
      >
        {options.map((option, key) => {
          return (
            <option value={option} key={key}>
              {`${option} ${label}`}
            </option>
          )
        })}
      </select>
    </div>
  ) : (
    <div className={className + " flex flex-col gap-2 p-3 rounded-xl border"}>
      <label htmlFor={label} className="text-xs font-light">
        {label}{" "}
        {note && (
          <span className="text-xs font-light text-gray-400">
            {" * " + note}
          </span>
        )}
      </label>
      <input
        id={label}
        type={type}
        className="w-full rounded font-semibold border-transparent outline-none focus:outline-none bg-transparent text-xs font-property"
        {...register(field)}
      />
    </div>
  )
}
