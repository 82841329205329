import { Transition, Dialog } from "@headlessui/react"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState, Fragment, useEffect } from "react"
import MenuIcons from "../icons/MenuIcons"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import { HiX } from "react-icons/hi"
export default function SlideShow({ images, name }) {
  const [isOpen, setIsOpen] = useState(false)
  const [current, setCurrent] = useState(0)
  const [direction, setDirection] = useState("FORWARD")

  useEffect(() => {
    if (current > images.length - 1) {
      setCurrent(0)
    } else if (current < 0) {
      setCurrent(images.length - 1)
    }
  }, [current, images])

  const handleDirection = (i, direction) => {
    setDirection(direction)
    setCurrent(value => value + 1 * i)
  }
  return (
    <div>
      <div className="absolute top-0 w-full h-full left-0 z-20 bg-[#2A3D50]/60 flex items-center justify-center">
        <button
          onClick={() => setIsOpen(true)}
          className="px-4 py-2 inline-flex gap-2 items-center rounded-full text-xs font-light text-white bg-[#2A3D50]/60 backdrop-blur outline-none focus:outline-none"
        >
          <span>
            <MenuIcons className="h-4 w-4 text-white" />
          </span>
          <span>Show all photos</span>
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/40 backdrop-blur" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full relative max-w-5xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <div className="absolute z-50 top-0 right-0">
                    <button
                      type="button"
                      onClick={() => setIsOpen(false)}
                      className="p-5 bg-white outline-none focus:outline-none"
                    >
                      <span className="sr-only">close slideshow</span>
                      <HiX className="text-2xl" />
                    </button>
                  </div>

                  <div className="absolute z-50 top-1/2">
                    <button
                      type="button"
                      onClick={() => handleDirection(-1, "BACKWARD")}
                      className="bg-white p-3 border rounded-md shadow-xl outline-none focus:outline-none"
                    >
                      <span className="sr-only">Previous</span>
                      <span>
                        <FaChevronLeft />
                      </span>
                    </button>
                  </div>
                  <div className="absolute z-50 top-1/2 right-0">
                    <button
                      type="button"
                      onClick={() => handleDirection(+1, "FORWARD")}
                      className="bg-white p-3 border rounded-md shadow-xl outline-none focus:outline-none"
                    >
                      <span className="sr-only">Next</span>
                      <span>
                        <FaChevronRight />
                      </span>
                    </button>
                  </div>
                  <div className="px-4 py-4 flex justify-center">
                    <div className="aspect-[4/3] grid relative rounded-xl overflow-hidden">
                      {images.map((photo, key) => {
                        return (
                          <Transition
                            as="div"
                            show={key === current ? true : false}
                            key={key}
                            className="col-start-1 row-start-1 flex flex-col gap-6"
                          >
                            <Transition.Child
                              enter="transition ease-in-out duration-300 transform"
                              enterFrom={`${
                                direction === "FORWARD"
                                  ? "translate-x-full opacity-0"
                                  : "-translate-x-full opacity-0"
                              }`}
                              enterTo="translate-x-0 opacity-100"
                              leave="transition ease-in-out duration-300 transform"
                              leaveFrom="translate-x-0 opacity-100"
                              leaveTo={`${
                                direction !== "FORWARD"
                                  ? "translate-x-full opacity-0"
                                  : "-translate-x-full opacity-0"
                              }`}
                              as="div"
                            >
                              <GatsbyImage
                                image={photo.asset.gatsbyImageData}
                                alt={`${name}-${key}`}
                                className="object-cover aspect-[4/3] w-full h-full"
                              />
                            </Transition.Child>
                          </Transition>
                        )
                      })}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}
